import React from "react"
import Button from "../../components/forms/button"
import Layout from "../../components/layout"
import { navigate } from "gatsby"

const Utils = () => {
  return (
    <Layout>
      <h2>Utilidades</h2>
      <h3>Tiempo</h3>
      <Button
        color="green"
        label="Convertir epoch/unix a fecha"
        onClick={() => {
          navigate("/utils/time")
        }}
      />
      <hr></hr>

      <h3>Listas</h3>
      <Button
        color="green"
        label="Manipular listas"
        onClick={() => {
          navigate("/utils/time")
        }}
      />
    </Layout>
  )
}

export default Utils
